import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby";
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const Data = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="データで見るスタートアッププラス｜About us｜株式会社スタートアッププラス" />

      <div className="block xl:flex justify-between">

        <div id="pageTitle" className="relative xl:fixed w-full xl:w-48 h-auto xl:h-full text-white bg-aboutusPage bg-no-repeat bg-center bg-cover  z-20 ">
          <h1 className="font-Copperplate text-center text-2xl sm:text-3xl py-10">
            About us
          </h1>
          <ul className="w-full text-center pb-5">
            <li className='mb-5'>
              <Link to="/aboutus-data" className=''>
                データで見るSUP
              </Link>
            </li>
            <li className='mb-5'>
              <Link to="/aboutus-environment" className=''>
                働く環境
              </Link>
            </li>
            <li className='mb-5'>
              <Link to="/aboutus-members" className=''>
                メンバー紹介
              </Link>
            </li>
          </ul>
        </div>

        <div id="main" className="static xl:ml-48 w-full">
          <div className=" w-11/12 max-w-[1000px] mx-auto py-10 sm:py-10">

            <section id="PageDescription" className="w-full mb-5 flex flex-wrap items-center justify-center lg:justify-evenly ">
              <h2 className="font-Copperplate text-center text-2xl sm:text-3xl leading-loose py-5">
                いろんな<span className="text-4xl sm:text-5xl text-key-pink ">データ</span>で<span className="text-4xl sm:text-5xl text-key-pink ">見る</span><br />
                <span className="text-3xl sm:text-4xl ">スタートアッププラス</span>
              </h2>
              <div className="">
              <StaticImage src="../images/data-top.png" className=" max-w-[400px] h-auto ml-3"/>
              <p className="text-right">
                ※2021年11月時点のデータです
              </p>
              </div>
            </section>

            <section id="staffData" className="mb-20">
              <div className="border-l-8 border-key-sand mt-10 mb-5 py-2 pl-3">
                <h2 className="text-2xl">働く社員</h2>
                <p className="mt-2">30代の社員を中心に、幅広い世代の社員が活躍中！</p>
              </div>
              <div className="flex flex-wrap justify-center sm:justify-start">
                <div className="m-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-8">年齢構成</h3>
                  <StaticImage src="../images/nenrei.png" className=" w-[280px] h-auto"/>
                </div>
                <div className="m-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-10">男女比</h3>
                  <StaticImage src="../images/danjyo.png" className=" w-[280px] h-auto"/>
                </div>
                <div className="m-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-8">職種</h3>
                  <StaticImage src="../images/job.png" className=" w-[280px] h-auto"/>
                </div>
              </div>
            </section>

            <section id="staffData" className="mb-20">
              <div className="border-l-8 border-key-sand mt-10 mb-10 py-2 pl-3">
                <h2 className="text-2xl">働き方・環境</h2>
                <p className="mt-2">フレックスタイムや在宅勤務制度を取り入れており、ワーク・ライフ・バランスを実現しやすい環境です。</p>
              </div>
              <div className="flex flex-wrap justify-center sm:justify-start">
                <div className="m-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-8">リモートワーク頻度</h3>
                  <StaticImage src="../images/remotework.png" className=" w-[280px] h-auto"/>
                </div>
                <div className="m-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-8">勤務地</h3>
                  <StaticImage src="../images/kinmuchi.png" className=" w-[280px] h-auto"/>
                </div>
                <div className="m-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-8">月平均残業時間</h3>
                  <StaticImage src="../images/zangyou.png" className=" w-[280px] h-auto"/>
                </div>
                <div className="m-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-8">勤務開始時間</h3>
                  <StaticImage src="../images/shigyou.png" className=" w-[280px] h-auto"/>
                </div>
                <div className="m-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-8">勤務終了時間</h3>
                  <StaticImage src="../images/syuugyou.png" className=" w-[280px] h-auto"/>
                </div>
                <div className="m-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-8">有給取得率</h3>
                  <StaticImage src="../images/yuukyuu.png" className=" w-[280px] h-auto"/>
                </div>
                <div className="m-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-8">勤務時の服装</h3>
                  <StaticImage src="../images/fukusou.png" className=" w-[280px] h-auto"/>
                </div>
                <div className="m-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-8">通勤時間</h3>
                  <StaticImage src="../images/gotowork.png" className=" w-[280px] h-auto"/>
                </div>
                <div className="m-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-8">メインOS割合</h3>
                  <StaticImage src="../images/os.png" className=" w-[280px] h-auto"/>
                </div>
              </div>
            </section>

            <section id="private" className="mb-20">
              <div className="border-l-8 border-key-sand mt-10 mb-5 py-2 pl-3">
                <h2 className="text-2xl">プライベート</h2>
                <p className="mt-2">スタッフにいろいろなプライベートなことを聞いてみました。</p>
              </div>
              <div className="flex flex-wrap justify-center sm:justify-start">
                <div className="m-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-8">理系？文系？</h3>
                  <StaticImage src="../images/rikei-bunkei.png" className=" w-[280px] h-auto"/>
                </div>
                <div className="m-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-8">お酒飲みますか？</h3>
                  <StaticImage src="../images/insyu.png" className=" w-[280px] h-auto"/>
                </div>
                <div className="m-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-8">喫煙者率</h3>
                  <StaticImage src="../images/kitsuen.png" className=" w-[280px] h-auto"/>
                </div>
                <div className="m-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-8">普段メガネする？</h3>
                  <StaticImage src="../images/megane.png" className=" w-[280px] h-auto"/>
                </div>
                <div className="m-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-8">いぬ派？ねこ派？</h3>
                  <StaticImage src="../images/dog-cat.png" className=" w-[280px] h-auto"/>
                </div>
                <div className="m-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-8">運動してる？</h3>
                  <StaticImage src="../images/sports.png" className=" w-[280px] h-auto"/>
                </div>
                <div className="m-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-8">ゲーム好き？</h3>
                  <StaticImage src="../images/game.png" className=" w-[280px] h-auto"/>
                </div>
                <div className="m-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-8">旅行には行く？</h3>
                  <StaticImage src="../images/travel.png" className=" w-[280px] h-auto"/>
                </div>
                <div className="m-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-8">インドア？アウトドア？</h3>
                  <StaticImage src="../images/in-out.png" className=" w-[280px] h-auto"/>
                </div>
              </div>
            </section>

            <section className="mb-20">
              <ul className="md:flex justify-evenly ">
                <li className="md:w-52 lg:w-72 border border-black p-2 mb-4">
                  <Link to="/recruit" className='block'>
                    <h3 className="font-Copperplate text-center text-2xl lg:text-3xl my-5">
                    RECRUIT
                    </h3>
                    <p className="text-center mb-5">
                      採用情報
                    </p>
                  </Link>
                </li>
                <li className="md:w-52 lg:w-72 border border-black p-2 mb-4">
                  <Link to="/aboutus-environment" className='block'>
                    <h3 className="font-Copperplate text-center text-2xl lg:text-3xl my-5">
                    ENVIRONMENT
                    </h3>
                    <p className="text-center mb-5">
                    働く環境
                    </p>
                  </Link>
                </li>
                <li className="md:w-52 lg:w-72 border border-black p-2 mb-4">
                  <Link to="/aboutus-members" className='block'>
                    <h3 className="font-Copperplate text-center text-2xl lg:text-3xl my-5">
                    MEMBERS
                    </h3>
                    <p className="text-center mb-5">
                    メンバー紹介
                    </p>
                  </Link>
                </li>
              </ul>
            </section>

          </div>
        </div>
      </div>

    </Layout>

    
  )
}



export default Data

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

